import React, { useState } from 'react';
import axios from 'axios';

import usuarioPerfilImg from '../img/usuario.jpg';

import '../Css/EstiloWed.css';

const PerfilUsuario = ({ nombre, informacion }) => {
    const [editing, setEditing] = useState(false);
    const [imagen, setImagen] = useState(null);
    const [formData, setFormData] = useState({
        cli_nombre: '',
        cli_apaterno: '',
        cli_amaterno: '',
        cli_correo: '',
        cli_usuario: '',
        cli_telefono: '',
        cli_direccion: { calle_No_casa: '', colonia: '' },
    });

    const userDataRaw = localStorage.getItem('userData');
    const userData = userDataRaw ? JSON.parse(userDataRaw) : null;
    const idUsuario = userData ? userData._id : null;


    const handleEdit = () => {
        setEditing(true);
        setFormData(userData); // Llenar el formulario con los datos del usuario
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleImageChange = (e) => {
        setImagen(e.target.files[0]);

    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {

            if (imagen) {
                console.log("1");
                const formData3 = new FormData();
                formData3.append('imagen', imagen);

                const uploadResponse = await axios.post('https://pract-8613e.uc.r.appspot.com/api/imagenes/upload', formData3);
                const imagenUrl = uploadResponse.data.url;

                console.log(imagenUrl);

                // Esperar a que se complete la subida de la imagen antes de actualizar el estado formData
                await axios.put(`https://pract-8613e.uc.r.appspot.com/api/clienteactualizar/${idUsuario}`, { ...formData, fotoPerfil: imagenUrl });
                        // Actualizar datos del usuario en localStorage si es necesario
            localStorage.setItem('userData', JSON.stringify(formData));

            } else  {
                console.log("2");
                await axios.put(`https://pract-8613e.uc.r.appspot.com/api/clienteactualizar/${idUsuario}`, formData);
            }
            console.log("3");
            console.log(formData);

            setEditing(false);

            // Actualizar datos del usuario en localStorage si es necesario
            localStorage.setItem('userData', JSON.stringify(formData));

        } catch (error) {
            console.error('Error al actualizar el usuario:', error);
        }
    };

    return (
        <center>
            <div className="perfil-container">
                <div className="tarjeta">
                    <div className="imagen-container">
                        <img src={userData ? userData.fotoPerfil : usuarioPerfilImg} alt="Foto de perfil" className="imagen-perfil" />
                    </div>
                    <h2 className="nombre-usuario">{userData ? `${userData.cli_nombre} ${userData.cli_apaterno} ${userData.cli_amaterno}` : 'Usuario'}</h2>
                    {editing ? (
                        <form onSubmit={handleSubmit}>
                            <label htmlFor="Nombre">Nombre:</label>
                            <input type="text" name="cli_nombre" value={formData.cli_nombre} onChange={handleChange} required />
                            <label htmlFor="Apaterno">Apaterno:</label>
                            <input type="text" name="cli_apaterno" value={formData.cli_apaterno} onChange={handleChange} required />
                            <label htmlFor="Amaterno">Amaterno:</label>
                            <input type="text" name="cli_amaterno" value={formData.cli_amaterno} onChange={handleChange} required />
                            <label htmlFor="Correo">Correo:</label>
                            <input type="email" name="cli_correo" value={formData.cli_correo} onChange={handleChange} required />
                            <label htmlFor="Telefono">Telefono:</label>
                            <input type="text" name="cli_telefono" value={formData.cli_telefono} onChange={handleChange} required />
                            <label htmlFor="calle_No_casa">calle_No_casa:</label>
                            <input type="text" name="calle_No_casa" value={formData.cli_direccion.calle_No_casa} onChange={handleChange} required />
                            <label htmlFor="Colonia">Colonia:</label>
                            <input type="text" name="colonia" value={formData.cli_direccion.colonia} onChange={handleChange} required />
                            <label htmlFor="Usuario">Usuario:</label>
                            <input type="text" name="cli_usuario" value={formData.cli_usuario} onChange={handleChange} required />
                            <label htmlFor="imagen">Imagen:</label><br />
                            <input type="file" id="imagen" name="imagen" accept="image/*" onChange={handleImageChange} />

                            <button type="submit">Guardar cambios</button>
                        </form>
                    ) : (
                        <button onClick={handleEdit}>Editar</button>
                    )}
                    <p className="informacion-usuario">
                        {userData ? `Correo electrónico: ${userData.cli_correo}` : ''}
                        <br />
                        {userData && userData.cli_telefono ? `Teléfono: ${userData.cli_telefono}` : ''}
                        <br />
                        {userData && userData.cli_direccion ? `Dirección: ${userData.cli_direccion.calle_No_casa}, ${userData.cli_direccion.colonia}` : ''}
                    </p>
                </div>
            </div>
        </center>
    );
};

export default PerfilUsuario;
