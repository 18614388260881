import React from 'react';

const HumidityWidget = ({ humidity }) => {
  const humidityValue = typeof humidity === 'number' ? humidity.toFixed(0) : '--';
  const radius = 45;
  const strokeWidth = 10;
  const circumference = 2 * Math.PI * radius;
  const progress = typeof humidity === 'number' ? (humidity / 100) * circumference : 0;

  
  return (
    <div style={styles.container}>

      <div style={styles.progressContainer}>
        <svg height={radius * 2 + strokeWidth} width={radius * 2 + strokeWidth}>
          <circle
            cx={radius + strokeWidth / 2}
            cy={radius + strokeWidth / 2}
            r={radius}
            fill="none"
            stroke="#B0E0E6"
            strokeWidth={strokeWidth}
          />
          <circle
            cx={radius + strokeWidth / 2}
            cy={radius + strokeWidth / 2}
            r={radius}
            fill="none"
            stroke="#1E90FF"
            strokeWidth={strokeWidth}
            strokeDasharray={`${progress} ${circumference}`}
            style={{ transform: `rotate(-90deg)`, transformOrigin: `${radius + strokeWidth / 2}px ${radius + strokeWidth / 2}px` }}
          />
        </svg>
        <div style={styles.humidityTextContainer}>
          <div style={styles.text}>{`${humidityValue}%`}</div>
        </div>
      </div>
      <div style={styles.text2}>Humedad</div>
    </div>
  );
};


const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    borderRadius: '15px',
    margin: '5px',
  },
  progressContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  humidityTextContainer: {
    position: 'absolute',
  },
  text: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#1E90FF',
  },
  icon: {
    marginBottom: '10px',
  },
  text2: {
    marginTop: '5px',
    fontSize: '14px',
    fontWeight: 'bold',
  },
};


export default HumidityWidget;



