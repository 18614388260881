import React from 'react';


const PaginaAdministrativa = () => {
  return (
    <div>
      <h1>Bienvenido a Nuestra Página de Inicio</h1>
      
    </div>
  );
};

export default PaginaAdministrativa;
